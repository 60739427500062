import React from 'react';
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import './events.css';
import { Card } from 'react-bootstrap';
import allevents from '../Data/events';
import allevent_cotegories from '../Data/event_categories';
import city_options from '../Data/city';

import { Button, Box } from '@mui/material';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import InsightsIcon from '@mui/icons-material/Insights';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ExpandIcon from '@mui/icons-material/Expand';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import moment from 'moment'
import Select from "react-select";
import { grey } from '@mui/material/colors';
import sort_arrow from './sorting_arrow.png';

let newFilterAttrs = {
  selectedEventCategoryIds: []
}

const getlocalTime = () => {
  let list = sessionStorage.getItem('timeval');
  if(list) {
    return JSON.parse(sessionStorage.getItem('timeval'));
  }
  else {
    return [0,24];
  }
}

const getlocalCost = () => {
  let list = sessionStorage.getItem('costval');
  if(list) {
    return JSON.parse(sessionStorage.getItem('costval'));
  }
  else {
    return [0,100];
  }
}

const getlocalSize = () => {
  let list = sessionStorage.getItem('sizeval');
  if(list) {
    return JSON.parse(sessionStorage.getItem('sizeval'));
  }
  else {
    return [0,100];
  }
}

const getlocalDis = () => {
  let list = sessionStorage.getItem('disval');
  if(list) {
    return JSON.parse(sessionStorage.getItem('disval'));
  }
  else {
    return [17];
  }
}

const formatOptionLabel = ({event_categoiry_name}) =>(
    <div style={{ display: "flex" }}>
      <div>{event_categoiry_name}</div>
    </div>
  );

const formatCityOptionLabel = ({ name }) => (
  <div style={{ display: "flex" }}>
    <div>{name}</div>
  </div>
);

const formatTimeLabel = ({ name }) => (
  <div style={{ display: "flex" }}>
    <div>{name}</div>
  </div>
);

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#463667',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 7,
    width: '100% !important',
    left: '0 !important',
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#121258',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> { }

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />

    </SliderThumb>
  );
}


function Events(props) {
  const [events, setEvents] = React.useState(allevents);
  const [listEvents, setlistEvents] = React.useState(allevents);
  const [order, setOrder] = useState('ASC');
  const [eventsCategoires, setEventCategories] = React.useState(allevent_cotegories);
  const [filterAttrs, changeFilterAttrValue] = useState(newFilterAttrs);
  const [active, setActive] = React.useState(1);
  const [disValue, setDisValue] = React.useState(getlocalDis);
  const [costValue, setCostValue] = React.useState(getlocalCost);
  const [sizeValue, setSizeValue] = React.useState(getlocalSize);
  const [timeValue, setTimeValue] = React.useState(getlocalTime);
  const [cat, setCat] = React.useState('All Events');
  const [venueSizeMax, setVeneSizeMax] = React.useState(20000);
  const [venueSizeMin, setVeneSizeMin] = React.useState(0);
  const [isActive1, setIsActive1] = React.useState(true);
  const [isActive2, setIsActive2] = React.useState(false);
  const [isActive3, setIsActive3] = React.useState(false);


  const coptions = [
    { id: 9, name: "Today, " + moment().format("MMM D") }
  ];

  const handleChange = (event, newValue) => {
    setDisValue(newValue);
  };

  const handleCostChange = (event, newValue) => {
    setCostValue(newValue);
  };

  const handleSizeChange = (event, newValue) => {
    setSizeValue(newValue);
    if (newValue[1] === 100)
      setVeneSizeMax('20000')
    else if (newValue[1] === 80)
      setVeneSizeMax('4000')
    else if (newValue[1] === 60)
      setVeneSizeMax('500')
    else if (newValue[1] === 40)
      setVeneSizeMax('100')
    else if (newValue[1] === 20)
      setVeneSizeMax('25')
    else { setVeneSizeMax('0') }

    if (newValue[0] === 100)
      setVeneSizeMin('20000')
    else if (newValue[0] === 80)
      setVeneSizeMin('4000')
    else if (newValue[0] === 60)
      setVeneSizeMin('500')
    else if (newValue[0] === 40)
      setVeneSizeMin('100')
    else if (newValue[0] === 20)
      setVeneSizeMin('25')
    else { setVeneSizeMin('0') }
  };

  const handleTimeChange = (event, newValue) => {
    setTimeValue(newValue);
  }

  const categorsiedEvents = (event_cotegory_id) => {
    return (
      events.filter(event => event.event_cotegory_id === event_cotegory_id)
    )
  }

  function isCost(val) {
    return numberRange(costValue[0], costValue[1]).includes(val.price)
  }

  function isSize(val) {
    return numberRange(sizeValue[0], sizeValue[1]).includes(val.price)
  }

  function isTime(val) {
    return numberRange(timeValue[0], timeValue[1]).includes(parseInt(moment(val.time, "HH").format("H"), 10))
  }

  function isDuration(val) {
    return numberRange(0, disValue).includes(val.duration)
  }

  //numberRange(s,e) will make range array
  function numberRange(start, end) {
    return new Array(end - start).fill().map((d, i) => i + start);
  }

  function nameA(event) {
    let ev = eventsCategoires.find(c => event.event_cotegory_id === c.id)
    return ev.event_categoiry_name
  }

  const sorting = (col) => {
    if (order === "ASC"){
      const sorted = [...listEvents].sort((a,b)=>
          a[col] > b[col] ? 1 : -1
          );
      setlistEvents(sorted);
      setOrder("DESC");
    }
    if (order === "DESC"){
      const sorted = [...listEvents].sort((a,b)=>
          a[col] < b[col] ? 1 : -1
          );
      setlistEvents(sorted);
      setOrder("ASC");
    }
  }

  const sortingTime = (col) => {
    if (order === "ASC"){
      const sorted = [...listEvents].sort((a,b)=>
          moment(a[col], "hh:mm:ss").unix() -
          moment(b[col], "hh:mm:ss").unix()
          );
      setlistEvents(sorted);
      setOrder("DESC");
    }
    if (order === "DESC"){
      const sorted = [...listEvents].sort((a,b)=>
          moment(b[col], "hh:mm:ss").unix() -
          moment(a[col], "hh:mm:ss").unix()
          );
      setlistEvents(sorted);
      setOrder("ASC");
    }
  }

  const handleSelectChange = (e) => {
    if (e == null) {
      changeFilterAttrValue({ selectedEventCategoryIds: [] })
    } else {
      changeFilterAttrValue({ selectedEventCategoryIds: [e] })
    }
  }

  const handlelistSelectChange = (e) => {
    (document.getElementsByClassName(" css-qc6sy-singleValue")[0].textContent
     = e.event_categoiry_name)
    if (e.id === 9) {
      setlistEvents(allevents)
    }
    else {
      setlistEvents(allevents.filter(event => event.event_cotegory_id === e.id))
    }
    setCat(e.event_categoiry_name)
  }

  let allEventCotegories

  // For event id == 9 all events will be showed
  if (filterAttrs.selectedEventCategoryIds.length > 0 &&
    filterAttrs.selectedEventCategoryIds[0].id !== 9) {
    allEventCotegories = filterAttrs.selectedEventCategoryIds
  } else {
    allEventCotegories = eventsCategoires
  }

  const SetView = (active) => {
    setActive(active);
  };

  const SetGrid = () => {
    setlistEvents(events);
  };

  const SetList = (category_id) => {
    setlistEvents(listEvents.filter(event => event.event_cotegory_id === category_id));
  };

  const SetCatName = (category) => {
    (category
      ? setCat(category.event_categoiry_name)
      : setCat(category)
    )
    (document.getElementsByClassName(" css-qc6sy-singleValue")[0].textContent
     = category.event_categoiry_name)
  };

  const SetColor1 = () => {
    setIsActive1(true)
    setIsActive2(false)
    setIsActive3(false)
  };

  const SetColor2 = () => {
    setIsActive2(true)
    setIsActive1(false)
    setIsActive3(false)
  };

  const SetColor3 = () => {
    setIsActive3(true)
    setIsActive1(false)
    setIsActive2(false)
  };

  let days = [];
  let daysRequired = 6
  let optionList = []
  for (let i = 0; i <= daysRequired; i++) {
    days.push(moment().add(i, 'days').format('dddd, Do MMMM YYYY'))
  }

  days.map((product, key) => {
    optionList.push({
      name: product
    });
  });

  let urlParams = Number(window.location.search.replace('?view=', ''))

  useEffect(()=> {
    sessionStorage.setItem('timeval', JSON.stringify(timeValue))
  }, [timeValue]);
  useEffect(()=> {
    sessionStorage.setItem('costval', JSON.stringify(costValue))
  }, [costValue]);
  useEffect(()=> {
    sessionStorage.setItem('sizeval', JSON.stringify(sizeValue))
  }, [sizeValue]);
  useEffect(()=> {
    sessionStorage.setItem('disval', JSON.stringify(disValue))
  }, [disValue]);

  return (
    <>
      <div className='row row1 row-sel'>
        {active === 2 || urlParams === 2 ?
          <div className="col">
            <Select
              defaultValue={eventsCategoires[9]}
              formatOptionLabel={formatOptionLabel}
              options={eventsCategoires}
              onChange={handlelistSelectChange}
            />
          </div>

          :
          <div className="col">
            <Select
              defaultValue={eventsCategoires[9]}
              formatOptionLabel={formatOptionLabel}
              options={eventsCategoires}
              onChange={handleSelectChange}
              isClearable={true}
            />
          </div>
        }

        <div className='col'>
          <Select
            id="tim"
            defaultValue={coptions[0]}
            formatOptionLabel={formatTimeLabel}
            options={optionList}
          />
        </div>

        <div className='col'>
          <Select
            defaultValue={city_options[0]}
            formatOptionLabel={formatCityOptionLabel}
            options={city_options}
          />
        </div>

        <div className='col siz justify'>
          <Button className="g_b mapbutton " aria-label="upload picture"
            style={{ backgroundColor: isActive1 && urlParams !== 2 ? '#a9b3bd' : '#f8f9fa' }}
            component={Link}
            to="/?view=0"
            onClick={() => { SetView(1); SetGrid(); SetCatName(null); SetColor1() }}>
            <ViewComfyIcon sx={{ fontSize: 30 }} className='buttonColor' />
          </Button>
          <Button className="g_b"
            style={{ backgroundColor: isActive2 || urlParams === 2 ? '#a9b3bd' : '#f8f9fa' }}
            onClick={() => { SetView(2); SetColor2(); sorting('duration') }} >
            <ViewListIcon sx={{ fontSize: 30 }} className='buttonColor'/>
          </Button>
          <Button className='mb' color="primary"
            style={{ backgroundColor: isActive3 ? '#a9b3bd' : '#f8f9fa' }}
            onClick={() => { SetColor3() }}
            component={Link}
            to="/map">
            <FmdGoodIcon sx={{ fontSize: 30 }} className='buttonColor' />
          </Button>
        </div>
      </div>

      <div className='row row1 row-slid'>
        <div className='bord'>
          <div className="col fil-col">
            <div className="row slid">
              <div className='col logo'>
                <AccessTimeFilledIcon className='test' sx={{ color: grey['A100'], fontSize: 35 }} />
              </div>

              <div className='col left'>
                <span className='left'>START:</span>
                <span ><strong>
                  {(timeValue[0] === 0
                    ? '12am'
                    : (timeValue[0] < 12
                      ? timeValue[0] + 'am'
                      : (timeValue[0] === 12
                        ? timeValue[0] + 'pm'
                        : (timeValue[0] - 12) + 'pm')))
                    + ' - ' +
                    (timeValue[1] === 24
                      ? '12am'
                      : (timeValue[1] < 12
                        ? (timeValue[1]) + 'am'
                        : (timeValue[1] === 12
                          ? timeValue[1] + 'pm'
                          : (timeValue[1] - 12) + 'pm')))
                  }
                </strong></span>
                <Box>
                  <AirbnbSlider
                    components={{ Thumb: AirbnbThumbComponent }}
                    min={0}
                    max={24}
                    step={1}
                    value={timeValue}
                    onChange={handleTimeChange}
                  />
                </Box>
              </div>
            </div>
          </div>

          <div className="col fil-col">
            <div className="row slid">
              <div className='col logo'>
                <MonetizationOnIcon className='test' sx={{ color: grey['A100'], fontSize: 35 }} />
              </div>
              <div className='col left'>
                <span className='left'>COST: </span>
                <strong><span >
                  {costValue[0] === 0
                    ? "Free - $" + costValue[1] + '+'
                    : '$' + costValue[0] + " - $" + costValue[1]}
                </span></strong>
                <Box>
                  <AirbnbSlider
                    components={{ Thumb: AirbnbThumbComponent }}
                    min={0}
                    max={100}
                    step={1}
                    value={costValue}
                    onChange={handleCostChange}
                  />
                </Box>
              </div>
            </div>
          </div>

          <div className="col fil-col">
            <div className="row slid">
              <div className='col logo'>
                <ExpandIcon className='test' sx={{ color: grey['A100'], fontSize: 35 }} />
              </div>
              <div className='col left'>
                <span className='left'>VENUE:</span>
                <strong><span >
                  {(venueSizeMin >= 4000 ? (venueSizeMin / 1000) + 'k' : venueSizeMin)
                    + ' - ' +
                    (venueSizeMax >= 4000 ? (venueSizeMax / 1000) + 'k' : venueSizeMax)
                    + ' ppl'
                  }
                </span></strong>
                <Box>
                  <AirbnbSlider
                    components={{ Thumb: AirbnbThumbComponent }}
                    min={0}
                    max={100}
                    step={20}
                    marks
                    value={sizeValue}
                    onChange={handleSizeChange}
                  />
                </Box>
              </div>
            </div>
          </div>

          <div className="col fil-col">
            <div className="row slid">
              <div className='col logo'>
                <InsightsIcon className='test' sx={{ color: grey['A100'], fontSize: 35 }} />
              </div>
              <div className='col left'>
                <span className='left'>MAX DISTANCE:</span>
                <span ><strong>
                  {(disValue[0] <= 10 ? (disValue[0] / 10) : disValue[0] - 9) + " mi"}
                </strong></span>
                <Box>
                  <AirbnbSlider
                    components={{ Thumb: AirbnbThumbComponent }}
                    min={0}
                    max={17}
                    value={disValue}
                    onChange={handleChange}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>

      {active === 2 || urlParams === 2 ?

        <div className="vh-70">
          <div className="row mar">
            <Card className='list-card'>
              <Card.Body>
                {
                  <h4>{cat ? cat : 'All Events'}</h4>
                }

                <Card.Text>
                  <div className="row row-cols-md-4" >
                    <div className="col data1">
                      <b onClick={()=> sortingTime('time')}>Time </b>
                      <img src={sort_arrow} alt='sort-logo' width="15" height="12" onClick={()=> sortingTime('time')}/>
                    </div>
                    <div className="col data2">
                      <b>Event Name</b>
                    </div>
                    <div className="col data3">
                      <b onClick={()=> sorting('duration')}>Distance </b>
                      <img src={sort_arrow} alt='sort-logo' width="15" height="12" onClick={()=> sorting('duration')}/>
                    </div>
                    <div className='showornot'>
                      <b>Category</b>
                    </div>
                  </div>
                  {
                    listEvents.filter(isDuration).filter(isCost).filter(isSize).filter(isTime).map(event => (
                      <div className="row row-cols-md-4 " key={event.id}>
                        <div className="col data1">
                          {moment.utc(event.time, "hh:mm:ss").format("hh:mm a")}
                        </div>
                        <div className="col data2">
                          {event.name}
                        </div>
                        <div className="col data3">
                          {event.duration}mi
                        </div>
                        <div className='showornot'>
                          {nameA(event)}
                        </div>
                      </div>
                    ))
                  }
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
        :
        <div className="vh-70">
          <div className="row row-cols-1  row-cols-md-3 row1 mar">
            {allEventCotegories.map(category => (
              <div className="col" id={category.class} key={category.id}>
                <Card key={category.id}>
                  <Card.Body>
                    <Card.Title className={category.class}>
                      <Card.Link className={category.class} href="#" onClick={() => { SetView(2); SetColor2(); SetList(category.id); SetCatName(category) }}>
                        {category.event_categoiry_name}
                      </Card.Link>
                    </Card.Title>
                    <Card.Text>
                      {
                        categorsiedEvents(category.id).slice(0, 5).filter(isDuration).filter(isCost).filter(isSize).filter(isTime).map(event => (
                          <div className="row row-cols-md-3 " key={event.id}>
                            <div className="col data1">
                              {moment(event.time, "HH:mm").format("h:mm a")}
                            </div>
                            <div className="col data2">
                              {event.name}
                            </div>
                            <div className="col data3">
                              {event.duration}mi
                            </div>
                          </div>
                        ))
                      }
                    </Card.Text>
                    {categorsiedEvents(category.id).length > 4 &&
                      <Card.Link href="#" onClick={() => { SetView(2); SetColor2(); SetList(category.id); SetCatName(category) }}>more...</Card.Link>
                    }
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      }
    </>
  );
}

export default Events;
export { getlocalTime, getlocalCost, getlocalSize, getlocalDis };
