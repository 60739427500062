import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom'
import map from './map.webp';
import './index.css';
import pin from './Maps_pin.png'
import InsightsIcon from '@mui/icons-material/Insights';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ExpandIcon from '@mui/icons-material/Expand';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/material';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import allevent_cotegories from '../Data/event_categories';
import city_options from '../Data/city';
import Select from "react-select";
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import moment from 'moment'
import {getlocalTime, getlocalCost, getlocalSize, getlocalDis} from './index.js';

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#463667',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 7,
    width: '100% !important',
    left: '0 !important',
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#121258',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> { }

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />

    </SliderThumb>
  );
}

const formatOptionLabel = ({ event_categoiry_name }) => (
  <div style={{ display: "flex" }}>
    <div>{event_categoiry_name}</div>
  </div>
);

const formatCityOptionLabel = ({ name }) => (
  <div style={{ display: "flex" }}>
    <div>{name}</div>
  </div>
);

const formatTimeLabel = ({ name }) => (
  <div style={{ display: "flex" }}>
    <div>{name}</div>
  </div>
);


function Main() {
  const [pin1Value, setPin1Value] = React.useState([getlocalTime()[0], getlocalTime()[1]]);
  const [pin2Value, setPin2Value] = React.useState([getlocalCost()[0], getlocalCost()[1]]);
  const [pin3Value, setPin3Value] = React.useState([getlocalSize()[0], getlocalSize()[1]]);
  const [pin4Value, setPin4Value] = React.useState([getlocalDis()[0]]);
  const [is1Greater1, setIs1Greater1] = React.useState(true)
  const [is1Greater2, setIs1Greater2] = React.useState(true)
  const [is1Greater3, setIs1Greater3] = React.useState(true)
  const [is1Greater4, setIs1Greater4] = React.useState(true)
  const [is1Greater5, setIs1Greater5] = React.useState(true)
  const [is1Greater6, setIs1Greater6] = React.useState(true)
  const [is1Greater7, setIs1Greater7] = React.useState(true)
  const [is1Greater8, setIs1Greater8] = React.useState(true)
  const [is1Greater9, setIs1Greater9] = React.useState(true)
  const [is1Greater10, setIs1Greater10] = React.useState(true)

  const [is2Greater1, setIs2Greater1] = React.useState(true)
  const [is2Greater2, setIs2Greater2] = React.useState(true)
  const [is2Greater3, setIs2Greater3] = React.useState(true)
  const [is2Greater4, setIs2Greater4] = React.useState(true)
  const [is2Greater5, setIs2Greater5] = React.useState(true)

  const [is3Greater1, setIs3Greater1] = React.useState(true)
  const [is3Greater2, setIs3Greater2] = React.useState(true)
  const [is3Greater3, setIs3Greater3] = React.useState(true)
  const [is3Greater4, setIs3Greater4] = React.useState(true)
  const [is3Greater5, setIs3Greater5] = React.useState(true)

  const [is4Greater1, setIs4Greater1] = React.useState(true)
  const [is4Greater2, setIs4Greater2] = React.useState(true)
  const [is4Greater3, setIs4Greater3] = React.useState(true)
  const [is4Greater4, setIs4Greater4] = React.useState(true)
  const [is4Greater5, setIs4Greater5] = React.useState(true)
  const [eventsCategoires, setEventCategories] = React.useState(allevent_cotegories);

  const [isActive1, setIsActive1] = React.useState(false);
  const [isActive2, setIsActive2] = React.useState(false);
  const [isActive3, setIsActive3] = React.useState(true);

  const [is5Greater1, setIs5Greater1] = React.useState(true)
  const [is5Greater2, setIs5Greater2] = React.useState(true)
  const [is5Greater3, setIs5Greater3] = React.useState(true)
  const [is5Greater4, setIs5Greater4] = React.useState(true)
  const [is5Greater5, setIs5Greater5] = React.useState(true)

  const [is6Greater1, setIs6Greater1] = React.useState(true)
  const [is6Greater2, setIs6Greater2] = React.useState(true)
  const [is6Greater3, setIs6Greater3] = React.useState(true)
  const [is6Greater4, setIs6Greater4] = React.useState(true)
  const [is6Greater5, setIs6Greater5] = React.useState(true)

  const [is7Greater1, setIs7Greater1] = React.useState(true)
  const [is7Greater2, setIs7Greater2] = React.useState(true)
  const [is7Greater3, setIs7Greater3] = React.useState(true)
  const [is7Greater4, setIs7Greater4] = React.useState(true)
  const [is7Greater5, setIs7Greater5] = React.useState(true)
  const [venueSizeMax, setVeneSizeMax] = React.useState(20000);
  const [venueSizeMin, setVeneSizeMin] = React.useState(0);

  useEffect(()=> {
    handleChange1([],[getlocalTime()[0], getlocalTime()[1]])
  }, []);

  useEffect(()=> {
    handleChange2([],[getlocalCost()[0], getlocalCost()[1]])
  }, []);

  useEffect(()=> {
    handleChange3([],[getlocalSize()[0], getlocalSize()[1]])
  }, []);

  useEffect(()=> {
    handleChange4([],[getlocalDis()[0]])
  }, []);

  const handleChange1 = (event, newValue) => {
    setPin1Value(newValue);

    newValue[0] > 0 ? setIs1Greater1(false) : setIs1Greater1(true)
    newValue[0] > 3 ? setIs1Greater2(false) : setIs1Greater2(true)
    newValue[0] > 6 ? setIs1Greater3(false) : setIs1Greater3(true)
    newValue[0] > 9 ? setIs1Greater4(false) : setIs1Greater4(true)
    newValue[0] > 12 ? setIs1Greater5(false) : setIs1Greater5(true)
    newValue[0] > 15 ? setIs1Greater6(false) : setIs1Greater6(true)
    newValue[0] > 18 ? setIs1Greater7(false) : setIs1Greater7(true)
    newValue[0] > 21 ? setIs1Greater8(false) : setIs1Greater8(true)
    newValue[0] > 24 ? setIs1Greater9(false) : setIs1Greater9(true)
    newValue[0] > 2 ? setIs1Greater10(false) : setIs1Greater10(true)

    newValue[1] < 24 ? setIs5Greater1(false) : setIs5Greater1(true)
    newValue[1] < 20 ? setIs5Greater2(false) : setIs5Greater2(true)
    newValue[1] < 15 ? setIs5Greater3(false) : setIs5Greater3(true)
    newValue[1] < 10 ? setIs5Greater4(false) : setIs5Greater4(true)
    newValue[1] < 5 ? setIs5Greater5(false) : setIs5Greater5(true)
  }
  const handleChange2 = (event, newValue) => {
    setPin2Value(newValue);
    newValue[0] > 10 ? setIs2Greater1(false) : setIs2Greater1(true)
    newValue[0] > 30 ? setIs2Greater2(false) : setIs2Greater2(true)
    newValue[0] > 50 ? setIs2Greater3(false) : setIs2Greater3(true)
    newValue[0] > 70 ? setIs2Greater4(false) : setIs2Greater4(true)
    newValue[0] > 90 ? setIs2Greater5(false) : setIs2Greater5(true)

    newValue[1] < 100 ? setIs6Greater1(false) : setIs6Greater1(true)
    newValue[1] < 80 ? setIs6Greater2(false) : setIs6Greater2(true)
    newValue[1] < 60 ? setIs6Greater3(false) : setIs6Greater3(true)
    newValue[1] < 40 ? setIs6Greater4(false) : setIs6Greater4(true)
    newValue[1] < 20 ? setIs6Greater5(false) : setIs6Greater5(true)
  }
  const handleChange3 = (event, newValue) => {
    setPin3Value(newValue);
    newValue[0] > 10 ? setIs3Greater1(false) : setIs3Greater1(true)
    newValue[0] > 30 ? setIs3Greater2(false) : setIs3Greater2(true)
    newValue[0] > 50 ? setIs3Greater3(false) : setIs3Greater3(true)
    newValue[0] > 70 ? setIs3Greater4(false) : setIs3Greater4(true)
    newValue[0] > 90 ? setIs3Greater5(false) : setIs3Greater5(true)

    newValue[1] < 100 ? setIs7Greater1(false) : setIs7Greater1(true)
    newValue[1] < 80 ? setIs7Greater2(false) : setIs7Greater2(true)
    newValue[1] < 60 ? setIs7Greater3(false) : setIs7Greater3(true)
    newValue[1] < 40 ? setIs7Greater4(false) : setIs7Greater4(true)
    newValue[1] < 20 ? setIs7Greater5(false) : setIs7Greater5(true)

    if (newValue[1] === 100)
      setVeneSizeMax('20000')
    else if (newValue[1] === 80)
      setVeneSizeMax('4000')
    else if (newValue[1] === 60)
      setVeneSizeMax('500')
    else if (newValue[1] === 40)
      setVeneSizeMax('100')
    else if (newValue[1] === 20)
      setVeneSizeMax('25')
    else { setVeneSizeMax('0') }

    if (newValue[0] === 100)
      setVeneSizeMin('20000')
    else if (newValue[0] === 80)
      setVeneSizeMin('4000')
    else if (newValue[0] === 60)
      setVeneSizeMin('500')
    else if (newValue[0] === 40)
      setVeneSizeMin('100')
    else if (newValue[0] === 20)
      setVeneSizeMin('25')
    else { setVeneSizeMin('0') }
  }
  const handleChange4 = (event, newValue) => {
    setPin4Value(newValue);
    newValue[0] < 3 ? setIs4Greater1(false) : setIs4Greater1(true)
    newValue[0] < 6 ? setIs4Greater2(false) : setIs4Greater2(true)
    newValue[0] < 9 ? setIs4Greater3(false) : setIs4Greater3(true)
    newValue[0] < 13 ? setIs4Greater4(false) : setIs4Greater4(true)
    newValue[0] < 17 ? setIs4Greater5(false) : setIs4Greater5(true)
  }

  const handleSelectChange = () => { }
  let days = [];
  let daysRequired = 6
  let optionList = []
  for (let i = 0; i <= daysRequired; i++) {
    days.push(moment().add(i, 'days').format('dddd, Do MMMM YYYY'))
  }
  const coptions = [
    { id: 9, name: "Today, " + moment().format("MMMM D") }
  ];

  const SetColor1 = () => {
    setIsActive1(true)
    setIsActive2(false)
    setIsActive3(false)
  };

  const SetColor2 = () => {
    setIsActive2(true)
    setIsActive1(false)
    setIsActive3(false)
  };

  const SetColor3 = () => {
    setIsActive3(true)
    setIsActive1(false)
    setIsActive2(false)
  };

  days.map((product, key) => {
    optionList.push({
      name: product
    });
  });

  return (
    <React.Fragment className ='mar'>
      <div className='row row1 row-sel '>
        <div className="col">
          <Select
            defaultValue={eventsCategoires[9]}
            options={eventsCategoires}
            formatOptionLabel={formatOptionLabel}
            onChange={handleSelectChange}
            isClearable={true}
          />
        </div>

        <div className='col'>
          <Select
            id="tim"
            defaultValue={coptions[0]}
            formatOptionLabel={formatTimeLabel}
            options={optionList}
          />
        </div>

        <div className='col'>
          <Select
            defaultValue={city_options[0]}
            formatOptionLabel={formatCityOptionLabel}
            options={city_options}
          />
        </div>

        <div className='col siz justify'>
          <Button className="g_b mapbutton" aria-label="upload picture"
            style={{ backgroundColor: isActive1 ? '#a9b3bd' : '#f8f9fa' }}
            component={Link}
            to="/"
            onClick={() => { SetColor1() }}>
            <ViewComfyIcon sx={{ fontSize: 30 }} className='buttonColor'/>
          </Button>

          <Button className="g_b mapbutton"
            style={{ backgroundColor: isActive2 ? '#a9b3bd' : '#f8f9fa' }}
            component={Link}
            to="/?view=2"
            onClick={() => { SetColor2() }}>
            <ViewListIcon sx={{ fontSize: 30 }} className='buttonColor'/>
          </Button>

          <Button className='mb' color="primary"
            style={{ backgroundColor: isActive3 ? '#a9b3bd' : '#f8f9fa' }}
            component={Link}
            to="/map"
            onClick={() => { SetColor3() }}>
            <FmdGoodIcon sx={{ fontSize: 30 }} className='buttonColor'/>
          </Button>
        </div>
      </div>

      <div className='row row1 row-slid'>
        <div className='bord'>
          <div className="col fil-col">
            <div className="row slid">
              <div className='col logo'>
                <AccessTimeFilledIcon className='test' sx={{ color: grey['A100'], fontSize: 35 }} />
              </div>

              <div className='col left'>
                <span className='left'>START:</span>
                <span ><strong>
                  {(pin1Value[0] === 0
                    ? '12am'
                    : (pin1Value[0] < 12
                      ? pin1Value[0] + 'am'
                      : (pin1Value[0] === 12
                        ? pin1Value[0] + 'pm'
                        : (pin1Value[0] - 12) + 'pm')))
                    + ' - ' +
                    (pin1Value[1] === 24
                      ? '12am'
                      : (pin1Value[1] < 12
                        ? (pin1Value[1]) + 'am'
                        : (pin1Value[1] === 12
                          ? pin1Value[1] + 'pm'
                          : (pin1Value[1] - 12) + 'pm')))
                  }
                </strong></span>
                <Box>
                  <AirbnbSlider
                    components={{ Thumb: AirbnbThumbComponent }}
                    min={0}
                    max={24}
                    step={1}
                    value={pin1Value}
                    onChange={handleChange1}
                  />
                </Box>
              </div>
            </div>
          </div>

          <div className="col fil-col">
            <div className="row slid">
              <div className='col logo'>
                <MonetizationOnIcon className='test' sx={{ color: grey['A100'], fontSize: 35 }} />
              </div>
              <div className='col left'>
                <span className='left'>COST: </span>
                <strong><span >
                  {pin2Value[0] === 0
                    ? "Free - $" + pin2Value[1] + '+'
                    : '$' + pin2Value[0] + " - $" + pin2Value[1]}
                </span></strong>
                <Box>
                  <AirbnbSlider
                    components={{ Thumb: AirbnbThumbComponent }}
                    min={0}
                    max={100}
                    step={1}
                    value={pin2Value}
                    onChange={handleChange2}
                  />
                </Box>
              </div>
            </div>
          </div>

          <div className="col fil-col">
            <div className="row slid">
              <div className='col logo'>
                <ExpandIcon className='test' sx={{ color: grey['A100'], fontSize: 35 }} />
              </div>
              <div className='col left'>
                <span className='left'>VENUE: </span>
                <strong><span >
                  {(venueSizeMin >= 4000 ? (venueSizeMin / 1000) + 'k' : venueSizeMin)
                    + ' - ' +
                    (venueSizeMax >= 4000 ? (venueSizeMax / 1000) + 'k' : venueSizeMax)
                    + ' ppl'
                  }
                </span></strong>
                <Box>
                  <AirbnbSlider
                    components={{ Thumb: AirbnbThumbComponent }}
                    min={0}
                    max={100}
                    step={20}
                    marks
                    value={pin3Value}
                    onChange={handleChange3}
                  />
                </Box>
              </div>
            </div>
          </div>

          <div className="col fil-col">
            <div className="row slid">
              <div className='col logo'>
                <InsightsIcon className='test' sx={{ color: grey['A100'], fontSize: 35 }} />
              </div>
              <div className='col left'>
                <span className='left'>MAX DISTANCE:</span>
                <span ><strong>
                  {(pin4Value[0] <= 10 ? (pin4Value[0] / 10) : pin4Value[0] - 9) + " mi "}
                </strong></span>
                <Box>
                  <AirbnbSlider
                    components={{ Thumb: AirbnbThumbComponent }}
                    min={0}
                    max={17}
                    value={pin4Value}
                    onChange={handleChange4}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row row1 vh mar">
        <div className='col parent'>
          <img src={map} alt='hello' className='imgMap responsive' />
          <img src={pin} id='1' alt='hello' className='imgPin imgPin0'
            style={{ display: is1Greater1 ? 'block' : 'none' }} />
          <img src={pin} id='2' alt='hello' className='imgPin imgPin1'
            style={{ display: is1Greater2 ? 'block' : 'none' }} />
          <img src={pin} id='3' alt='hello' className='imgPin imgPin2'
            style={{ display: is1Greater3 ? 'block' : 'none' }} />
          <img src={pin} id='4' alt='hello' className='imgPin imgPin3'
            style={{ display: is1Greater4 ? 'block' : 'none' }} />
          <img src={pin} id='5' alt='hello' className='imgPin imgPin4'
            style={{ display: is1Greater5 ? 'block' : 'none' }} />
          <img src={pin} id='6' alt='hello' className='imgPin imgPin5'
            style={{ display: is1Greater6 ? 'block' : 'none' }} />
          <img src={pin} id='7' alt='hello' className='imgPin imgPin6'
            style={{ display: is1Greater7 ? 'block' : 'none' }} />
          <img src={pin} id='8' alt='hello' className='imgPin imgPin7'
            style={{ display: is1Greater8 ? 'block' : 'none' }} />
          <img src={pin} id='9' alt='hello' className='imgPin imgPin8'
            style={{ display: is1Greater9 ? 'block' : 'none' }} />
          <img src={pin} id='10' alt='hello' className='imgPin imgPin9'
            style={{ display: is1Greater10 ? 'block' : 'none' }} />

          <img src={pin} id='11' alt='hello' className='imgPin imgPin10'
            style={{ display: is2Greater1 ? 'block' : 'none' }} />
          <img src={pin} id='12' alt='hello' className='imgPin imgPin11'
            style={{ display: is2Greater2 ? 'block' : 'none' }} />
          <img src={pin} id='13' alt='hello' className='imgPin imgPin12'
            style={{ display: is2Greater3 ? 'block' : 'none' }} />
          <img src={pin} id='14' alt='hello' className='imgPin imgPin13'
            style={{ display: is2Greater4 ? 'block' : 'none' }} />
          <img src={pin} id='15' alt='hello' className='imgPin imgPin14'
            style={{ display: is2Greater5 ? 'block' : 'none' }} />

          <img src={pin} id='16' alt='hello' className='imgPin imgPin15'
            style={{ display: is3Greater1 ? 'block' : 'none' }} />
          <img src={pin} id='17' alt='hello' className='imgPin imgPin16'
            style={{ display: is3Greater2 ? 'block' : 'none' }} />
          <img src={pin} id='18' alt='hello' className='imgPin imgPin17'
            style={{ display: is3Greater3 ? 'block' : 'none' }} />
          <img src={pin} id='19' alt='hello' className='imgPin imgPin18'
            style={{ display: is3Greater4 ? 'block' : 'none' }} />
          <img src={pin} id='20' alt='hello' className='imgPin imgPin19'
            style={{ display: is3Greater5 ? 'block' : 'none' }} />

          <img src={pin} id='21' alt='hello' className='imgPin imgPin20'
            style={{ display: is4Greater1 ? 'block' : 'none' }} />
          <img src={pin} id='22' alt='hello' className='imgPin imgPin21'
            style={{ display: is4Greater2 ? 'block' : 'none' }} />
          <img src={pin} id='23' alt='hello' className='imgPin imgPin22'
            style={{ display: is4Greater3 ? 'block' : 'none' }} />
          <img src={pin} id='24' alt='hello' className='imgPin imgPin23'
            style={{ display: is4Greater4 ? 'block' : 'none' }} />
          <img src={pin} id='25' alt='hello' className='imgPin imgPin24'
            style={{ display: is4Greater5 ? 'block' : 'none' }} />

          <img src={pin} id='26' alt='hello' className='imgPin imgPin25'
            style={{ display: is5Greater1 ? 'block' : 'none' }} />
          <img src={pin} id='27' alt='hello' className='imgPin imgPin26'
            style={{ display: is5Greater2 ? 'block' : 'none' }} />
          <img src={pin} id='28' alt='hello' className='imgPin imgPin27'
            style={{ display: is5Greater3 ? 'block' : 'none' }} />
          <img src={pin} id='29' alt='hello' className='imgPin imgPin28'
            style={{ display: is5Greater4 ? 'block' : 'none' }} />
          <img src={pin} id='30' alt='hello' className='imgPin imgPin29'
            style={{ display: is5Greater5 ? 'block' : 'none' }} />

          <img src={pin} id='31' alt='hello' className='imgPin imgPin30'
            style={{ display: is6Greater1 ? 'block' : 'none' }} />
          <img src={pin} id='32' alt='hello' className='imgPin imgPin31'
            style={{ display: is6Greater2 ? 'block' : 'none' }} />
          <img src={pin} id='33' alt='hello' className='imgPin imgPin32'
            style={{ display: is6Greater3 ? 'block' : 'none' }} />
          <img src={pin} id='34' alt='hello' className='imgPin imgPin33'
            style={{ display: is6Greater4 ? 'block' : 'none' }} />
          <img src={pin} id='35' alt='hello' className='imgPin imgPin34'
            style={{ display: is6Greater5 ? 'block' : 'none' }} />

          <img src={pin} id='36' alt='hello' className='imgPin imgPin35'
            style={{ display: is7Greater1 ? 'block' : 'none' }} />
          <img src={pin} id='37' alt='hello' className='imgPin imgPin36'
            style={{ display: is7Greater2 ? 'block' : 'none' }} />
          <img src={pin} id='38' alt='hello' className='imgPin imgPin37'
            style={{ display: is7Greater3 ? 'block' : 'none' }} />
          <img src={pin} id='39' alt='hello' className='imgPin imgPin38'
            style={{ display: is7Greater4 ? 'block' : 'none' }} />
          <img src={pin} id='40' alt='hello' className='imgPin imgPin39'
            style={{ display: is7Greater5 ? 'block' : 'none' }} />

        </div>
      </div>
    </React.Fragment>
  )
};

export default Main;
