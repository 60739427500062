import React from 'react';
import '../NavBar/NavBar.css';
import { Navbar, Nav} from 'react-bootstrap';
import CopyrightIcon from '@mui/icons-material/Copyright';
import logo from '../NavBar/name_logo.png';


const Footer = ()=> {
   return (

    <Navbar collapseOnSelect expand="lg" variant="dark" className='navStick'>
      <Navbar.Brand href="/"><img src={logo} alt='logo'/></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="mailto:info@zoxwerx.com" target='blank'>Send us your feedback</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link href="/video">About</Nav.Link>
          <Nav.Link href="">Help</Nav.Link>
          <Nav.Link href="">Terms</Nav.Link>
          <Nav.Link href="">Privacy</Nav.Link>
        </Nav>
        <h6>2022 <CopyrightIcon className="copy"/>SAMMYSAYS</h6>
      </Navbar.Collapse>
    </Navbar>

    );
}

export default Footer;