import React from 'react';
import './NavBar.css';
import logo from './name_logo.png';
import { Navbar, Nav, Form, FormControl } from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';

function Navigation(){
  const popup = () => {
    alert("Sorry! Currently unavailable.")
  }
   return (

    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Navbar.Brand href="/"><img src={logo} alt='logo'/></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="">To Post & Find Events Nearby</Nav.Link>
          <Nav.Link onClick={popup}>Post Events +</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link href="/video" className='spacing'>About</Nav.Link>
        </Nav>
        <Form className="d-flex">
          <FormControl
            type="search"
            placeholder="Find Events..."
            className="me-2"
            aria-label="Search"
          />
         <SearchIcon className="search"/>
        </Form>
      </Navbar.Collapse>
    </Navbar>   
    );
}

export default Navigation;
