import React from 'react';

const games = [
  { id:61, name: "Metal Monday", time: "20:00:00", duration: 1, price: 14, event_cotegory_id:4 },
  { id:62, name: "Oddcake Presents: Recess", time: "20:00:00", duration: 7, price: 8, event_cotegory_id: 4 },
  { id:63, name: "Evil Army, Birth AD", time: "20:00:00", duration: 2, price: 14, event_cotegory_id: 6 },
  { id:64, name: "Bill Kirchen", time: "18:00:00", duration: 5, price: 0, event_cotegory_id: 3 },
  { id:65, name: "Chris Smither", time: "18:00:00", duration: 4, price: 5, event_cotegory_id: 2 },
  { id:66, name: "Peoples Temple", time: "21:59:59", duration: 5, price: 1, event_cotegory_id: 1 },
  { id:67, name: "Matt Portella", time: "12:30:00", duration: 1, price: 5, event_cotegory_id: 5 },
  { id:68, name: "Christine Havrilla & Gypsy Fuzz", time: "11:11:00", duration: 3, price: 15, event_cotegory_id: 7 },
  { id:69, name: "We Are The Flash Mob", time: "11:40:00", duration: 8, price: 20, event_cotegory_id: 0 },
  { id:70, name: "Nick Gill", time: "04:40:00", duration: 6, price: 10, event_cotegory_id: 5 },
];

export default games;
