import React from 'react';
import music from '../Data/music';
import stage from '../Data/stage';
import active from '../Data/active';
import family from '../Data/family';
import film from '../Data/film';
import food from '../Data/food';
import games from '../Data/games';
import gathering from '../Data/gathering';
import talks from '../Data/talks';

const events = [].concat(music, stage, active, family, film, food, games, gathering, talks)

export default events;