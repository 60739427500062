import React from 'react';

const food = [
  { id:51, name: "Metal Monday", time: "02:59:00", duration: 0, price: 0, event_cotegory_id:8 },
  { id:52, name: "Oddcake Presents: Recess", time: "06:29:00", duration: 3, price: 15, event_cotegory_id: 3 },
  { id:53, name: "Evil Army, Birth AD", time: "04:20:00", duration: 1, price: 95, event_cotegory_id: 2 },
  { id:54, name: "Bill Kirchen", time: "18:00:00", duration: 5, price: 10, event_cotegory_id: 2 },
  { id:55, name: "Chris Smither", time: "18:00:00", duration: 3, price: 25, event_cotegory_id: 0 },
  { id:56, name: "Peoples Temple", time: "21:59:59", duration: 5, price: 55, event_cotegory_id: 5 },
  { id:57, name: "Matt Portella", time: "8:00:00", duration: 1, price: 60, event_cotegory_id: 4 },
  { id:58, name: "Christine Havrilla & Gypsy Fuzz", time: "06:30:00", duration: 3, price: 1, event_cotegory_id: 1 },
  { id:59, name: "We Are The Flash Mob", time: "03:30:00", duration: 8, price: 15, event_cotegory_id: 6 },
  { id:60, name: "Nick Gill", time: "11:11:00", duration: 0, price: 100, event_cotegory_id: 7 },
];

export default food;
