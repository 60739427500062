import React from 'react';

const talks = [
  { id:81, name: "Metal Monday", time: "20:00:00", duration: 1, price: 8, event_cotegory_id:0 },
  { id:82, name: "Oddcake Presents: Recess", time: "20:00:00", duration: 7, price: 7, event_cotegory_id: 5 },
  { id:83, name: "Evil Army, Birth AD", time: "20:00:00", duration: 2, price: 27, event_cotegory_id: 7 },
  { id:84, name: "Bill Kirchen", time: "18:00:00", duration: 5, price: 35, event_cotegory_id: 5 },
  { id:85, name: "Chris Smither", time: "18:00:00", duration: 4, price: 33, event_cotegory_id: 4 },
  { id:86, name: "Peoples Temple", time: "21:59:59", duration: 5, price: 47, event_cotegory_id: 6 },
  { id:87, name: "Matt Portella", time: "12:30:00", duration: 3, price: 21, event_cotegory_id: 5 },
  { id:88, name: "Christine Havrilla & Gypsy Fuzz", time: "12:30:00", duration: 4, price: 31, event_cotegory_id: 3 },
  { id:89, name: "We Are The Flash Mob", time: "12:40:00", duration: 9, price: 47, event_cotegory_id: 5 },
  { id:90, name: "Nick Gill", time: "12:40:00", duration: 6, price: 62, event_cotegory_id: 5 },
];

export default talks;