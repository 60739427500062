import React from 'react';
import './App.css';
import Navigation from './Components/NavBar/Navigation';
import Footer from './Components/Footer/footer';
import Events from './Components/Events/index';
import Main from'./Components/Events/map.js';
import Video from'./Components/Events/video.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {

  return (
    <div className="App">
        <Navigation />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Events />}/>
            <Route path="map" element={<Main />}/>
            <Route path="video" element={<Video />}/>
          </Routes>
        </BrowserRouter>
        <Footer />
    </div>

  );
}

export default App;
