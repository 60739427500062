import React from 'react';

const family = [
  { id:31, name: "Metal Monday", time: "02:00:00", duration: 0, price: 5, event_cotegory_id:4 },
  { id:32, name: "Oddcake Presents: Recess", time: "20:00:00", duration: 8, price: 30, event_cotegory_id: 6 },
  { id:33, name: "Evil Army, Birth AD", time: "15:00:00", duration: 1, price: 88, event_cotegory_id: 0 },
  { id:34, name: "Bill Kirchen", time: "18:00:00", duration: 1, price: 99, event_cotegory_id: 8 },
];

export default family;
