import React from 'react';

const event_categoires = [
  { id:0, event_categoiry_name: "Music", color: 'red', class: 'm'},
  { id:1, event_categoiry_name: "Stage/Art/Culture", color: 'red', class: 's'},
  { id:2, event_categoiry_name: "Film/Media", color: 'red', class: 'fm'},
  { id:3, event_categoiry_name: "Food/Drink/Sales", color: 'red', class: 'fd'},
  { id:4, event_categoiry_name: "Gathering/Happening", color: 'red', class: 'gh'},
  { id:5, event_categoiry_name: "Games/Competitions", color: 'red', class: 'gc'},
  { id:6, event_categoiry_name: "Education/Talks/Biz", color: 'red', class: 'e'},
  { id:7, event_categoiry_name: "Active/Fitness", color: 'red', class: 'a'},
  { id:8, event_categoiry_name: "Community/Family", color: 'red', class: 's'},
  { id:9, event_categoiry_name: "All Events", color: null, class: 'all_e'}

]
export default event_categoires;