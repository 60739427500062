import React from 'react';

const stage = [
  { id:11, name: "Metal Monday", time: "03:00:00", duration: 1, price: 8, event_cotegory_id:2 },
  { id:12, name: "Oddcake Presents: Recess", time: "01:00:00", duration: 7, price: 12, event_cotegory_id: 0 },
  { id:13, name: "Evil Army, Birth AD", time: "20:00:00", duration: 2, price: 25, event_cotegory_id: 5 },
  { id:14, name: "Bill Kirchen", time: "18:00:00", duration: 1, price: 45, event_cotegory_id: 0 },
  { id:15, name: "Chris Smither", time: "15:00:00", duration: 4, price: 20, event_cotegory_id: 1 },
  { id:16, name: "Peoples Temple", time: "21:59:59", duration: 5, price: 10, event_cotegory_id: 0 },
  { id:17, name: "Matt Portella", time: "12:30:00", duration: 3, price: 75, event_cotegory_id: 4 },
  { id:18, name: "Christine Havrilla & Gypsy Fuzz", time: "12:30:00", duration: 2, price: 12, event_cotegory_id: 0 },
  { id:19, name: "We Are The Flash Mob", time: "12:30:00", duration: 8, price: 25, event_cotegory_id: 0 },
  { id:20, name: "Nick Gill", time: "12:30:00", duration: 2, price: 10, event_cotegory_id: 8 },
];

export default stage;