import React from 'react';

const gathering = [
  { id:71, name: "Metal Monday", time: "20:00:00", duration: 3, price: 1, event_cotegory_id:3 },
  { id:72, name: "Oddcake Presents: Recess", time: "20:00:00", duration: 7, price: 3, event_cotegory_id: 4 },
  { id:73, name: "Evil Army, Birth AD", time: "20:00:00", duration: 2, price: 8, event_cotegory_id: 3 },
  { id:74, name: "Bill Kirchen", time: "18:00:00", duration: 9, price: 10, event_cotegory_id: 2 },
  { id:75, name: "Chris Smither", time: "18:00:00", duration: 4, price: 63, event_cotegory_id: 3 },
  { id:76, name: "Peoples Temple", time: "21:59:59", duration: 5, price: 30, event_cotegory_id: 4 },
  { id:77, name: "Matt Portella", time: "01:30:00", duration: 6, price: 15, event_cotegory_id: 3 },
  { id:78, name: "Christine Havrilla & Gypsy Fuzz", time: "02:30:00", duration: 3, price: 21, event_cotegory_id: 5 },
  { id:79, name: "We Are The Flash Mob", time: "05:30:00", duration: 9, price: 22, event_cotegory_id: 7 },
  { id:80, name: "Nick Gill", time: "00:30:00", duration: 2, price: 45, event_cotegory_id: 3 },
];

export default gathering;
