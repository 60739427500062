import React from 'react';

const music = [
  { id:1, name: "Oddcake Presents: Recess", time: "20:00:00", duration: 7, price: 10, event_cotegory_id: 6 },
  { id:2, name: "Evil Army, Birth AD", time: "20:00:00", duration: 6, price: 18, event_cotegory_id: 6 },
  { id:3, name: "Bill Kirchen", time: "18:00:00", duration: 1, price: 22, event_cotegory_id: 2 },
  { id:4, name: "Chris Smither", time: "18:00:00", duration: 4, price: 47, event_cotegory_id: 4 },
  { id:5, name: "Peoples Temple", time: "21:59:59", duration: 9, price: 57, event_cotegory_id: 6 },
  { id:6, name: "Matt Portella", time: "07:30:00", duration: 6, price: 34, event_cotegory_id: 5 },
  { id:7, name: "Christine Havrilla & Gypsy Fuzz", time: "03:33:00", duration: 3, price: 20, event_cotegory_id: 4 },
  { id:8, name: "We Are The Flash Mob", time: "02:39:00", duration: 8, price: 1, event_cotegory_id: 3 },
  { id:9, name: "Nick Gill", time: "10:10:00", duration: 2, price: 65, event_cotegory_id: 6 },
  { id:10, name: "Jesus' Older Brother", time: "09:09:00", duration: 1, price: 90, event_cotegory_id: 6 }
];

export default music;
