import React from 'react';
import { Button } from '@mui/material';
import { Link, withRouter } from 'react-router-dom'
import './index.css';
import VideoLink from './SammySays.mp4';

function Video() {
  return (
    <div className='vid'>
      <video className='video' controls autostart autoPlay src={VideoLink} type="video/mp4" />
    </div>
  )
}

export default Video;
