const active = [
  { id:21, name: "Metal Monday", time: "03:00:00", duration: 1, price: 5, event_cotegory_id:0 },
  { id:22, name: "Oddcake Presents: Recess", time: "01:30:00", duration: 7, price: 10, event_cotegory_id: 2 },
  { id:23, name: "Evil Army, Birth AD", time: "20:00:00", duration: 2, price: 95, event_cotegory_id: 5 },
  { id:24, name: "Bill Kirchen", time: "17:00:00", duration: 5, price: 99, event_cotegory_id: 7 },
  { id:25, name: "Chris Smither", time: "18:00:00", duration: 4, price: 20, event_cotegory_id: 6 },
  { id:26, name: "Peoples Temple", time: "21:59:59", duration: 5, price: 2, event_cotegory_id: 7 },
  { id:27, name: "Matt Portella", time: "11:30:00", duration: 3, price: 15, event_cotegory_id: 1 },
  { id:28, name: "Christine Havrilla & Gypsy Fuzz", time: "10:00:00", price: 96, duration: 3, event_cotegory_id: 8 },
  { id:29, name: "We Are The Flash Mob", time: "07:20:00", duration: 8, price: 51, event_cotegory_id: 3 },
  { id:30, name: "Nick Gill", time: "09:40:00", duration: 0, price: 80, event_cotegory_id: 7 },
];


export default active;
