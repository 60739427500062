import React from 'react';

const film = [
  { id:41, name: "Metal Monday", time: "10:01:00", duration: 1, price: 1, event_cotegory_id: 1 },
  { id:42, name: "Oddcake Presents: Recess", time: "09:09:00", duration: 7, price: 3, event_cotegory_id: 1 },
  { id:43, name: "Evil Army, Birth AD", time: "08:08:00", duration: 2, price: 8, event_cotegory_id: 2 },
  { id:44, name: "Bill Kirchen", time: "08:50:00", duration: 1, price: 15, event_cotegory_id: 3 },
  { id:45, name: "Chris Smither", time: "08:20:00", duration: 4, price: 42, event_cotegory_id: 5 },
  { id:46, name: "Peoples Temple", time: "21:59:59", duration: 5, price: 63, event_cotegory_id: 0 },
  { id:47, name: "Matt Portella", time: "02:30:00", duration: 3, price: 5, event_cotegory_id: 4 },
  { id:48, name: "Christine Havrilla & Gypsy Fuzz", time: "01:30:00", duration: 3, price: 17, event_cotegory_id: 1 },
  { id:49, name: "We Are The Flash Mob", time: "5:00:00", duration: 8, price: 22, event_cotegory_id: 7 },
  { id:50, name: "Nick Gill", time: "8:45:00", duration: 2, price: 34, event_cotegory_id: 0 },
];

export default film;
